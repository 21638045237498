import {Button, Card, CardContent} from '@mui/material';
import React, {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid2';
import {CheckInService} from '../../services/CheckInService';
import {Trans} from 'react-i18next';
import CardHeading from '../shared/CardHeading';

export default function WorkSettingCard() {

    type WorkSettingsConfig = { [key: string]: string };

    const workSettingsConfig: WorkSettingsConfig = {
        'ONSITE': '#73BBA3',
        'HOME_OFFICE': '#FFE9D0',
        'CLIENT': '#BBE9FF'
    };

    const [workSettings, setWorkSettings] = useState<{ [key: string]: number } | null>(null);

    function getAllWorkSettings() {
        CheckInService.getAllWorkSettings().then(result => setWorkSettings(result));
    }

    useEffect(() => {
        getAllWorkSettings();
    }, []);

    function updateWorkSetting(workSetting: string) {
        CheckInService.updateMood(null, workSetting).then(() => getAllWorkSettings());
    }

    const totalCounts = workSettings ? Object.values(workSettings).reduce((acc: number, count: number) => acc + count, 0) : 0;

    return (
        <Card sx={{minWidth: 275, mt: 3}}>
            <CardContent>
                <CardHeading i18nKeyTitle="workSettings.workQuestion" dataTestId="workSettings-title" />
                <Grid container className="workGrid" alignItems={'center'}>
                    <Grid size={6}>
                        {
                            Object.entries(workSettingsConfig).map(([workSetting, color]) => (
                                <div key={workSetting} data-testid={'bubbleLegend-' + workSetting}>
                                    <div className="bubbleLegend" style={{backgroundColor: color}}></div>
                                    <Trans i18nKey={"workSettings.workAnswers." + workSetting}></Trans>
                                </div>
                            ))
                        }
                    </Grid>
                    <Grid size={6}>
                        <Grid container spacing={1} className="workTracker" sx={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(2, 1fr)',
                            gridTemplateRows: 'auto auto',
                            justifyItems: 'center',
                            alignItems: 'center',
                            gap: '0',
                        }}>
                            {workSettings
                                ? Object.entries(workSettings).map(([workSetting, count], index) => {
                                    const percentage = totalCounts > 0 ? count / totalCounts : 0;
                                    const bubbleSize = Math.max(50, Math.min(256, percentage * 150));
                                    return (
                                        <div
                                            key={workSetting}
                                            className="workSettingBubble"
                                            style={{
                                                width: `${bubbleSize}px`,
                                                height: `${bubbleSize}px`,
                                                backgroundColor: workSettingsConfig[workSetting],
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                borderRadius: '50%',
                                                margin: '10px',
                                                gridColumn: index === 2 ? '1 / span 2' : 'auto',
                                            }}
                                        >
                                            <div className="bubbleText" data-testid={'workSetting-count-' + workSetting }>{count}</div>
                                        </div>
                                    );
                                })
                                : null
                            }
                        </Grid>
                    </Grid>
                </Grid>

                <div className="workTracker">
                    {
                        Object.keys(workSettingsConfig).map((workSetting) => (
                            <Button key={workSetting} variant="contained"
                                    data-testid={'workSettingButton-' + workSetting}
                                    onClick={() => updateWorkSetting(workSetting)}>
                                <Trans i18nKey={"workSettings.workAnswers." + workSetting}></Trans>
                            </Button>
                        ))
                    }
                </div>
            </CardContent>
        </Card>
    );
}
