export interface User {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    teams: string[];
    companyId: string;
    role: GenkiRole;
    locked: boolean;
}

export interface AuthenticationResponse {
    token: string;
}

export interface Thought {
    idea: string;
    userName: string;
    id: number;
}


export interface Team {
    id: string;
    name: string;
}

export interface ModalDialogProps {
    open: boolean;
    onClose: () => void;
}

export interface TeamModalDialogProps extends ModalDialogProps {
    team: Team;
    onChange: (team: Team) => void;
}

export interface UserModalDialogProps extends ModalDialogProps {
    user: User;
    availableTeams: Record<string, string>;
    onChange: (user: User) => void;
}

export interface DeleteDialogProps extends ModalDialogProps {
    deletionProps:  DeletionProps
}

export interface DeletionProps {
    deleteText: string;
    deleteId: string;
    userOfTeams: Record<string, User[]>;
    deleteEntity: 'user' | 'team';
}

export interface UserCardProps {
    user: User;
    teams: Record<string, string>;
    setDeletionProps: (deletionProps: DeletionProps) => void;
    setDeleteModalIsOpen: (bool: boolean) => void;
    setUserToEdit: (user: User) => void;
    setUserModalIsOpen : (bool: boolean) => void;
    fetchData: () => void;
    userOfTeams: Record<string, User[]>;
}

export enum GenkiRole {
    USER = "USER",
    ADMIN = "ADMIN",
}

export interface CardHeadingProps {
    i18nKeyTitle: string;
    dataTestId: string;
}
