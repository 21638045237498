import api from '../config/AxiosConfig';
import {AuthenticationResponse} from '../model/Models';


export class AuthenticationService {

    static async login(email: string, password: string): Promise<AuthenticationResponse> {
        const response = await api.post('/auth/login', {email: email, password: password});
        return response.data;
    }

    static async logout(): Promise<void> {
        const response = await api.get('/auth/logout');
        return response.data;
    }
}