import {Typography} from '@mui/material';
import {Trans} from 'react-i18next';
import React from 'react';
import {CardHeadingProps} from '../../model/Models';

export default function CardHeading(props: CardHeadingProps) {
    return (
        <Typography sx={{fontWeight: 700, fontSize: '16px'}} data-testid={props.dataTestId}>
            <Trans i18nKey={props.i18nKeyTitle}></Trans>
        </Typography>
    );
}

