import {
    Box,
    Button,
    Card,
    CardContent,
    Container, Paper,
    Step,
    StepContent,
    StepLabel,
    Stepper, TextField,
    Typography
} from '@mui/material';
import {Trans} from 'react-i18next';
import React, {FormEvent, useState} from 'react';
import Grid from '@mui/material/Grid2';
import {PasswordRecoveryService} from '../services/PasswordRecoveryService';
import {useNavigate} from 'react-router-dom';
import CardHeading from '../components/shared/CardHeading';
import {t} from 'i18next';

export default function PasswordRecovery() {
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [token, setToken] = useState<number>(0);
    const [activeStep, setActiveStep] = useState(0);

    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');

    const steps = [
        {
            label: 'passwordRecovery.enterEmail',
            description: 'passwordRecovery.enterEmailDescription',
        },
        {
            label: 'passwordRecovery.enterToken',
            description: 'passwordRecovery.enterTokenDescription',
        },
        {
            label: 'passwordRecovery.enterNewPassword',
            description: 'passwordRecovery.enterNewPasswordDescription',
        },
    ];

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    function navigateToLoginPage() {
        setActiveStep(0);
        navigate('/login');
    }

    const handleBack = () => {
        if (activeStep === 0) {
            navigateToLoginPage();
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };


    async function handleSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if (activeStep === 0) {
            PasswordRecoveryService.startPasswordRecovery(email).then(() => {
                handleNext();
            });
        }

        if (activeStep === 1) {
            PasswordRecoveryService.verifyPasswordRecoveryToken(email, token).then(resp => {
                if (resp.status === 200) {
                    handleNext();
                }
            });
        }
        if (activeStep === 2) {
            PasswordRecoveryService.resetPassword(email, password).then(resp => {
                if (resp.status === 200) {
                    handleNext();
                }
            });
        }
    }

    function isValidPassword(password: string, passwordRepeat: string): boolean {
        const passwordEquals = password === passwordRepeat;
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumber = /[0-9]/.test(password);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
        const isValidLength = password.length >= 8;

        return passwordEquals && hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar && isValidLength;
    }

    function isFourDigitNumber(num: number): boolean {
        return num >= 1000 && num <= 9999;
    }

    function isNextStepButtonDisabled(): boolean {
        if (activeStep === 0 && email.trim() === '') {
            return true;
        }
        if (activeStep === 1 && (token === 0 || !isFourDigitNumber(token))) {
            return true;
        }
        return activeStep === 2 && !isValidPassword(password, passwordRepeat);
    }


    return (
        <Container maxWidth="md">
            <Card className="loginCard" sx={{minWidth: 275}}>
                <CardContent>
                    <CardHeading dataTestId="passwordRecovery-title" i18nKeyTitle="passwordRecovery.title"/>
                    <Stepper activeStep={activeStep} orientation="vertical">
                        {steps.map((step, index) => (
                            <Step key={step.label}>
                                <StepLabel data-testid={'step-label-' + index}>
                                    <Trans i18nKey={step.label}></Trans>
                                </StepLabel>
                                <StepContent>
                                    <Typography data-testid={'step-description-' + index}>
                                        <Trans i18nKey={step.description}></Trans>
                                    </Typography>
                                    <form onSubmit={handleSubmit}>
                                        <Box sx={{mb: 2}}>
                                            {activeStep === 0 && (
                                                <Grid size={{xs: 12, md: 4, sm: 6}}>
                                                    <TextField label={t('general.email')} variant="outlined" required
                                                               fullWidth type="email"
                                                               value={email}
                                                               onChange={(e) => setEmail(e.target.value)}
                                                               slotProps={{htmlInput: {'data-testid': 'email-' + index}}}/>
                                                </Grid>

                                            )}

                                            {activeStep === 1 && (
                                                <Grid size={{xs: 12, md: 4, sm: 6}}>
                                                    <TextField label={t('passwordRecovery.token')} variant="outlined"
                                                               required type="number"
                                                               fullWidth
                                                               onChange={(e) => setToken(Number(e.target.value))}
                                                               slotProps={{htmlInput: {'data-testid': 'token-' + index}}}/>

                                                    {
                                                        token !== 0 && !isFourDigitNumber(token) ?
                                                            <Typography color="error"
                                                                        data-testid={'token-validation-error-' + index}>
                                                                <Trans
                                                                    i18nKey="passwordRecovery.tokenValidation"></Trans>
                                                            </Typography>
                                                            : <Box/>
                                                    }
                                                </Grid>
                                            )}

                                            {activeStep === 2 && (
                                                <Box>
                                                    <Grid size={{xs: 12, md: 4, sm: 6}}>
                                                        <TextField label={t('general.password')} variant="outlined"
                                                                   required
                                                                   type="password" fullWidth
                                                                   onChange={(e) => setPassword(e.target.value)}
                                                                   slotProps={{htmlInput: {'data-testid': 'password-' + index}}}
                                                        />
                                                    </Grid>
                                                    <Grid size={{xs: 12, md: 4, sm: 6}}>
                                                        <TextField label={t('general.password')} variant="outlined"
                                                                   required
                                                                   type="password" fullWidth
                                                                   onChange={(e) => setPasswordRepeat(e.target.value)}
                                                                   slotProps={{htmlInput: {'data-testid': 'password-repeat-' + index}}}
                                                        />
                                                    </Grid>
                                                    <Grid>
                                                        {
                                                            password.trim() !== '' && passwordRepeat !== '' &&
                                                            !isValidPassword(password, passwordRepeat) ?
                                                                <Typography color="error"
                                                                            data-testid={'password-validation-error-' + index}>
                                                                    <Trans
                                                                        i18nKey="passwordRecovery.passwordValidation"></Trans>
                                                                </Typography>
                                                                : <Box/>
                                                        }
                                                    </Grid>
                                                </Box>
                                            )}

                                            <Box display="flex" justifyContent="space-between">
                                                {activeStep === 0 && (
                                                    <Button data-testid="backToLogin"
                                                            onClick={handleBack}
                                                            sx={{mt: 1, mr: 1}}>
                                                        <Trans i18nKey="passwordRecovery.backToLogin"></Trans>
                                                    </Button>
                                                )}

                                                {activeStep !== 0 && (
                                                    <Button
                                                        data-testid={'prevStep-' + index}
                                                        onClick={handleBack}
                                                        sx={{mt: 1, mr: 1}}>
                                                        <Trans i18nKey="passwordRecovery.back"></Trans>
                                                    </Button>
                                                )}
                                                <Button
                                                    variant="contained"
                                                    data-testid={'nextStep-' + index}
                                                    sx={{mt: 1, mr: 1}}
                                                    type="submit"
                                                    disabled={isNextStepButtonDisabled()}
                                                >
                                                    {index === steps.length - 1 ?
                                                        <Trans i18nKey="passwordRecovery.finish"></Trans> :
                                                        <Trans i18nKey="passwordRecovery.next"></Trans>
                                                    }
                                                </Button>
                                            </Box>
                                        </Box>
                                    </form>
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>

                    {activeStep === steps.length && (
                        <Paper square elevation={0} sx={{p: 3}}>
                            <Typography data-testid="password-recovery-end"><Trans
                                i18nKey="passwordRecovery.end"></Trans></Typography>
                            <Button onClick={navigateToLoginPage} sx={{mt: 1, mr: 1}}
                                    data-testid="password-recovery-end-back">
                                <Trans i18nKey="passwordRecovery.backToLogin"></Trans>
                            </Button>
                        </Paper>
                    )}
                </CardContent>
            </Card>
        </Container>
    );
}