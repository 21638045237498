import api from '../config/AxiosConfig';
import {AxiosResponse} from 'axios';

export class PasswordRecoveryService {

    static async startPasswordRecovery(email: string): Promise<void> {
        return await api.post('/password-recovery/forgot-password', {email: email});
    }

    static async verifyPasswordRecoveryToken(email: string, token: number): Promise<AxiosResponse> {
        return await api.post('/password-recovery/verify-token', {email: email, token: token});
    }

    static async resetPassword(email: string, password: string): Promise<AxiosResponse> {
        return await api.post('/password-recovery/reset-password', {email: email,password: password});
    }
}