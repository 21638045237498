import api from '../config/AxiosConfig';
import {User} from '../model/Models';

export class UserService {

    static async getUser(): Promise<User> {
        const response = await api.get('/user');
        return response.data;
    }

    static async deleteUser(userId: string): Promise<void> {
        const response = await api.delete('/user/' + userId);
        return response.data;
    }

    static async createOrUpdateUser(user: User): Promise<void> {
        const response = await api.post('/user', user);
        return response.data;
    }


    static async getUsersOfCompanyGroupedByTeam(): Promise<Record<string, User[]>> {
        const response = await api.get('/user/groupedByTeam/' + UserService.getCurrentUser()?.companyId);
        return response.data;
    }

    static async unlockUser(userId: string): Promise<void> {
        const response = await api.get('/user/unlock/' + userId);
        return response.data;
    }

    static getCurrentUser(): User | undefined {
        const userString = localStorage.getItem('user');
        let user: User;
        if (userString) {
            user = JSON.parse(userString);
            return user;
        }
    }

}
