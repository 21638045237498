import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});

api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

export const setupAxiosInterceptor = ({setError}: { setError: any }) => {
    api.interceptors.response.use((response) => response, (error) => {
        if (error.response) {
            if (error.response.status === 403) {
                window.location.href = '/login';
                localStorage.clear();
            }
           setError(error.response.data);
        }
        return error;
    });
}


export default api;