import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import {
    Avatar,
    Box,
    Typography,
    Drawer, List, ListItem, ListItemText, Link
} from '@mui/material';
import {AuthenticationService} from '../../services/AuthenticationService';
import {useNavigate} from 'react-router-dom';
import {UserService} from '../../services/UserService';
import {theme} from '../../styles/GenkiStyle';
import {Trans} from 'react-i18next';
import Grid from '@mui/material/Grid2';
import {useState} from 'react';
import {GenkiRole} from '../../model/Models';

export default function MainNavigation() {

    const navigationEntries: Record<string, string> = {
        'dashboard': '/',
        'team': '/team',
        'feedback': '/feedback',
        'thinkTank': '/think-tank'
    };

    const navigate = useNavigate();


    function logout() {
        toggleDrawer(false);
        AuthenticationService.logout().then(() => {
            localStorage.clear();
            navigate('/login');
        });
    }

    const [drawerState, setDrawerState] = useState(false);

    const toggleDrawer = (open: boolean) => {
        setDrawerState(open);
    };

    function isActiveLink(path: string) {
        return path === window.location.pathname;
    }


    return (
        <Box>
            <AppBar sx={{
                padding: '12px 15px 0px 15px',
                maxHeight: '9%',
                boxShadow: 'none',
                backgroundColor: 'transparent',
                backdropFilter: 'blur(8px)',
                zIndex: theme.zIndex.drawer + 1,
            }} position="fixed">
                <Grid container direction="row" sx={{justifyContent: 'space-between', alignItems: 'center'}}>
                    <Grid>
                        <img data-testid="genki-logo" src={'/GenkiLogoV3.svg'} alt={'logo'}/>
                    </Grid>
                    {
                        UserService.getCurrentUser() ?
                            <React.Fragment>
                                {
                                    Object.entries(navigationEntries).map(([key, value]) => {
                                        return (
                                            <Grid sx={{display: {xs: 'none', sm: 'none', md: 'block', lg: 'block'}}}
                                                  key={key}>
                                                <Link key={key} href={value} data-testid={key}
                                                      className={isActiveLink(value) ? 'active' : ''}>
                                                    <Typography sx={{color: 'primary'}}>
                                                        <Trans i18nKey={'navigation.' + key}></Trans>
                                                    </Typography>
                                                </Link>
                                            </Grid>
                                        );
                                    })
                                }
                                {
                                    UserService.getCurrentUser()?.role === GenkiRole.ADMIN &&
                                    <Grid sx={{display: {xs: 'none', sm: 'none', md: 'block', lg: 'block'}}}>
                                        <Link data-testid="admin-link" href="/administration"
                                              className={isActiveLink('/administration') ? 'active' : ''}>
                                            <Typography sx={{textAlign: 'center', color: 'primary'}}>
                                                <Trans i18nKey={'navigation.administration'}></Trans>
                                            </Typography>
                                        </Link>
                                    </Grid>
                                }
                                <Grid sx={{display: {xs: 'none', sm: 'none', md: 'block', lg: 'block'}}}>
                                    <Avatar data-testid="logout" onClick={() => logout()}></Avatar>
                                </Grid>

                                <Grid sx={{display: {xs: 'block', sm: 'block', md: 'none', lg: 'none'}}}>
                                    <IconButton
                                        onClick={() => toggleDrawer(!drawerState)}
                                        size="large"
                                        edge="start"
                                        aria-label="menu"
                                        sx={{mr: 2, color: 'black'}}>
                                        <MenuIcon/>
                                    </IconButton>
                                </Grid>
                            </React.Fragment> : null}
                </Grid>
            </AppBar>
            <Drawer anchor="top" open={drawerState} sx={{'&.MuiDrawer-root .MuiDrawer-paper': {marginTop: '60px'}}}
                    ModalProps={{keepMounted: true}} slotProps={{backdrop: {invisible: true}}}>
                <List>
                    {
                        Object.entries(navigationEntries).map(([key, value]) => {
                            return (
                                <ListItem key={key}>
                                    <ListItemText>
                                        <Link href={value}>
                                            <Typography sx={{color: 'primary'}}>
                                                <Trans i18nKey={'navigation.' + key}></Trans>
                                            </Typography>
                                        </Link>
                                    </ListItemText>
                                </ListItem>
                            );
                        })
                    }
                    <ListItem>
                        <ListItemText onClick={() => logout()}>
                            <Typography sx={{color: 'primary', cursor: 'pointer'}}>
                                <Trans i18nKey="navigation.logout"></Trans>
                            </Typography>
                        </ListItemText>
                    </ListItem>
                </List>
            </Drawer>
        </Box>
    );
}