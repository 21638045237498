import React, {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid2';
import {Button, Typography} from '@mui/material';
import {TeamService} from '../services/TeamService';
import {UserService} from '../services/UserService';
import {Delete, Edit} from '@mui/icons-material';
import {Trans} from 'react-i18next';
import {TeamModalDialog} from '../components/administration/TeamModalDialog';
import {DeletionProps, GenkiRole, Team, User} from '../model/Models';
import {UserModalDialog} from '../components/administration/UserModalDialog';
import {DeleteModalDialog} from '../components/administration/DeleteModalDialog';
import {UserCard} from '../components/administration/UserCard';

export default function Administration() {
    const [teamModalIsOpen, setTeamModalIsOpen] = useState<boolean>(false);
    const [userModalIsOpen, setUserModalIsOpen] = useState<boolean>(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState<boolean>(false);

    const [teams, setTeams] = useState<Record<string, string>>();
    const [usersOfTeam, setUsersOfTeam] = useState<Record<string, User[]>>();
    const [deletionProps, setDeletionProps] = useState<DeletionProps>();

    const [teamToEdit, setTeamToEdit] = useState<Team>();
    const [userToEdit, setUserToEdit] = useState<User>();

    function fetchData() {
        UserService.getUsersOfCompanyGroupedByTeam().then(resp => {
            setUsersOfTeam(resp);
        });
        TeamService.getTeamsOfCompany().then(teams => {
            setTeams(teams);
        });
    }

    useEffect(() => {
        fetchData();
    }, []);

    function deleteTeam(teamId: string, teamName: string) {
        const deletionProps: DeletionProps = {
            userOfTeams: {},
            deleteId: teamId,
            deleteText: teamName,
            deleteEntity: 'team'
        };
        setDeletionProps(deletionProps);
        setDeleteModalIsOpen(true);
    }

    function editTeam(teamId: string, teamName: string) {
        setTeamToEdit({id: teamId, name: teamName});
        setTeamModalIsOpen(true);
    }

    function addTeam() {
        setTeamToEdit({id: '', name: ''});
        setTeamModalIsOpen(true);
    }


    function addUser() {
        setUserToEdit({
            companyId: '',
            email: '',
            firstName: '',
            id: '',
            lastName: '',
            locked: false,
            role: GenkiRole.USER,
            teams: []
        });
        setUserModalIsOpen(true);
    }

    function closeTeamDialog() {
        setTeamModalIsOpen(false);
        setTeamToEdit(undefined);
        fetchData();
    }

    async function closeDeleteDialog() {
        setDeleteModalIsOpen(false);
        setDeletionProps(undefined);
        fetchData();
    }

    function closeUserDialog() {
        setUserModalIsOpen(false);
        setUserToEdit(undefined);
        fetchData();
    }

    return (
        <Grid padding={2}>
            <Grid container spacing={2}>
                <Button onClick={() => addUser()} data-testid="addUser"><Trans
                    i18nKey="administration.addUser"></Trans></Button>
                <Button onClick={() => addTeam()} data-testid="addTeam"><Trans
                    i18nKey="administration.addTeam"></Trans></Button>
            </Grid>
            {teams && Object.entries(teams).map(([teamId, teamName]) => {
                return (
                    <Grid key={teamId} mt={2}>
                        <Grid className="borderBottom" container spacing={2}>
                            <Grid size={11} sx={{display: 'flex', justifyContent: 'center'}}>
                                <Typography variant="h5" data-testid={'teamHeading-' + teamId}>{teamName}</Typography>
                            </Grid>
                            <Grid size={1} sx={{display: 'flex', justifyContent: 'flex-start'}}>
                                <Edit onClick={() => editTeam(teamId, teamName)} className="clickIcon"
                                      data-testid={'editTeam-' + teamId}/>
                                <Delete onClick={() => deleteTeam(teamId, teamName)} className="clickIcon"
                                        data-testid={'deleteTeam-' + teamId}/>
                            </Grid>
                        </Grid>
                        <Grid container mt={2} spacing={2}>
                            {usersOfTeam && Array.isArray(usersOfTeam[teamId]) &&
                                usersOfTeam[teamId].map((user) => {
                                        return (<UserCard user={user} teams={teams}
                                                          setDeletionProps={setDeletionProps}
                                                          setDeleteModalIsOpen={setDeleteModalIsOpen}
                                                          setUserModalIsOpen={setUserModalIsOpen}
                                                          setUserToEdit={setUserToEdit}
                                                          fetchData={fetchData}
                                                          userOfTeams={usersOfTeam}
                                                          key={user.id}
                                        />);
                                    }
                                )}
                        </Grid>
                    </Grid>
                );
            })}

            {deletionProps && <DeleteModalDialog
                deletionProps={deletionProps}
                open={deleteModalIsOpen}
                onClose={() => closeDeleteDialog()}/>}
            {teamToEdit && <TeamModalDialog open={teamModalIsOpen}
                                            team={teamToEdit}
                                            onChange={setTeamToEdit}
                                            onClose={() => closeTeamDialog()}/>
            }
            {teams && userToEdit && <UserModalDialog open={userModalIsOpen}
                                                     user={userToEdit}
                                                     onChange={setUserToEdit}
                                                     availableTeams={teams}
                                                     onClose={() => closeUserDialog()}/>
            }
        </Grid>
    );
}