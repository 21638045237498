import {Box, Button, Card, CardContent, Container, Link, TextField} from '@mui/material';
import React, {FormEvent, useState} from 'react';
import {AuthenticationService} from '../services/AuthenticationService';
import {useNavigate} from 'react-router-dom';
import {UserService} from '../services/UserService';
import {Trans} from 'react-i18next';
import CardHeading from '../components/shared/CardHeading';
import {t} from 'i18next';

export function Login() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    function login(email: string, password: string) {
        AuthenticationService.login(email, password).then((result) => {
            localStorage.setItem('token', result.token);
            UserService.getUser().then(user => {
                localStorage.setItem('user', JSON.stringify(user));
                navigate('/');
            });
        });
    }

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        login(email, password);
    };

    return (
        <Container maxWidth="sm">
            <form onSubmit={handleSubmit}>
                <Card className="loginCard" sx={{minWidth: 275}}>
                    <CardContent>
                        <CardHeading i18nKeyTitle="login.login" dataTestId="login-title"/>
                        <TextField label={t('general.email')} variant="outlined" required type="email"
                                   fullWidth
                                   slotProps={{htmlInput: {'data-testid': 'email'}}}
                                   onChange={(e) => setEmail(e.target.value)}/>
                        <TextField label={t('general.password')} variant="outlined" required type="password"
                                   slotProps={{htmlInput: {'data-testid': 'password'}}}
                                   fullWidth
                                   onChange={(e) => setPassword(e.target.value)}/>
                        <Box display="flex" justifyContent="space-between">
                            <Link data-testid="forgot-password" href="/forgot-password">
                                <Trans i18nKey="login.forgotPassword"></Trans>
                            </Link>
                            <Button variant="contained" type="submit" data-testid="login-button"
                                    disabled={email.trim() === '' || password.trim() === ''}>
                                <Trans i18nKey="login.login"></Trans>
                            </Button>
                        </Box>
                    </CardContent>
                </Card>
            </form>
        </Container>
    );
}