import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material';
import {DeleteDialogProps} from '../../model/Models';
import {Trans} from 'react-i18next';
import {t} from 'i18next';
import {UserService} from '../../services/UserService';
import {TeamService} from '../../services/TeamService';

export function DeleteModalDialog(props: Readonly<DeleteDialogProps>) {

    async function handleDelete() {
        if (props.deletionProps.deleteEntity === 'user') {
            await UserService.deleteUser(props.deletionProps.deleteId);
        } else {
            await TeamService.deleteTeam(props.deletionProps.deleteId);
            await removeDeletedTeamFromUser(props.deletionProps.deleteId);
        }
        props.onClose();
    }

    async function removeDeletedTeamFromUser(teamId: string) {
        if (Array.isArray(props.deletionProps.userOfTeams[teamId])) {
            for (const user of props.deletionProps.userOfTeams[teamId]) {
                user.teams.splice(user.teams.indexOf(teamId), 1);
                await UserService.createOrUpdateUser(user);
            }
        }
    }

    return (
        <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth={'md'}>
            <DialogTitle>
                <Typography data-testid="delete-title">
                    <Trans i18nKey="administration.delete"></Trans>
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Typography data-testid="delete-content">
                    <Trans>{t('administration.deleteQuestion', {deleteTarget: props.deletionProps.deleteText})}</Trans>
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} data-testid="cancel-button">
                    <Trans i18nKey="general.cancel"></Trans>
                </Button>
                <Button onClick={() => handleDelete()} data-testid="confirm-delete-button">
                    <Trans i18nKey="general.delete"></Trans>
                </Button>
            </DialogActions>
        </Dialog>

    );
}