import Grid from '@mui/material/Grid2';
import {Card, CardContent, Typography} from '@mui/material';
import {Delete, Edit, Lock, LockOpen} from '@mui/icons-material';
import {t} from 'i18next';
import React from 'react';
import {DeletionProps, User, UserCardProps} from '../../model/Models';
import {UserService} from '../../services/UserService';

export function UserCard(props: Readonly<UserCardProps>) {

    function deleteUser(user: User) {
        const deletionProps: DeletionProps = {
            deleteId: user.id,
            deleteText: user.firstName + ' ' + user.lastName,
            deleteEntity: 'user',
            userOfTeams: props.userOfTeams,
        };
        props.setDeletionProps(deletionProps);
        props.setDeleteModalIsOpen(true);
    }

    async function unlockUser(userId: string) {
        UserService.unlockUser(userId).then(() => {
            props.fetchData();
        });
    }

    function editUser(user: User) {
        props.setUserToEdit(user);
        props.setUserModalIsOpen(true);
    }

    return (
        <Grid size={{xs: 12, lg: 4, md: 4}} key={props.user.id}>
            <Card>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid size={6}>
                            <Typography
                                data-testid={'userHeading-' + props.user.id}>
                                {props.user.firstName} {props.user.lastName}
                            </Typography>
                        </Grid>
                        <Grid size={6} sx={{display: 'flex', justifyContent: 'flex-end'}}>
                            {props.user.locked ?
                                <Lock onClick={() => unlockUser(props.user.id)}
                                      data-testid={'unlockUser-' + props.user.id} className="clickIcon"/> :
                                <LockOpen data-testid={'userLock-' + props.user.id}  className="icon"/>}
                            <Edit onClick={() => editUser(props.user)} className="clickIcon"
                                  data-testid={'editUser-' + props.user.id}/>

                            <Delete onClick={() => deleteUser(props.user)}
                                    data-testid={'deleteUser-' + props.user.id}
                                    className="clickIcon"/>
                        </Grid>
                        <Grid size={6} data-testid={'firstName-' + props.user.id}>
                            {t('administration.user.firstName')}: {props.user.firstName}
                        </Grid>
                        <Grid size={6} data-testid={'lastName-' + props.user.id}>
                            {t('administration.user.lastName')}: {props.user.lastName}
                        </Grid>
                        <Grid size={6} data-testid={'email-' + props.user.id}>
                            {t('general.email')}: {props.user.email}
                        </Grid>
                        <Grid size={6} data-testid={'teams-' + props.user.id}>
                            {t('administration.user.teams')}:
                            {props.user.teams.map((team, index) => {
                                return (
                                    <span
                                        key={team}> {props.teams[team]}{index + 1 !== props.user.teams.length ? ',' : ''}
                                    </span>
                                );
                            })}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
}