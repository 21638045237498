import React from 'react';
import {Box} from '@mui/material';

export const TabPanel: React.FC<{ children: React.ReactNode; value: number; index: number }> = ({children, value, index}) => {
    return (
        <Box role="tabpanel" hidden={value !== index} sx={{padding: 0}}>
            {value === index && <Box sx={{padding: 0}}>{children}</Box>}
        </Box>
    );
};
