import {Box, Button, Card, CardContent, TextField, Typography} from '@mui/material';
import React, {FormEvent, useEffect, useState} from 'react';
import Grid from '@mui/material/Grid2';
import {ThoughtsPoolService} from '../../services/ThoughtsPoolService';
import {Trans } from 'react-i18next';
import CardHeading from '../shared/CardHeading';
import {Thought} from '../../model/Models';
import {t} from 'i18next';

export default function ThoughtsPool() {
    const maxCharsThought = 180;

    const [value, setValue] = useState('');
    const [thoughts, setThoughts] = useState<Thought[]>([]);

    function postMessage() {
        ThoughtsPoolService.saveThoughts(value).then(() => getAllThoughts());
        setValue('');
    }

    function getAllThoughts() {
        ThoughtsPoolService.getAllThoughts().then(resp => {
            setThoughts(resp);
        });
    }

    useEffect(() => {
        getAllThoughts();
    }, []);


    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        postMessage();
    };

    return (
        <Card sx={{minWidth: 275}}>
            <CardContent>
                <CardHeading i18nKeyTitle="thoughtsPool.headline" dataTestId="thought-pool-title"></CardHeading>
                <form onSubmit={handleSubmit}>
                    <TextField fullWidth placeholder={t('thoughtsPool.placeholder')} value={value}
                               slotProps={{htmlInput: {'data-testid': 'thought-pool-text'}}}
                               onChange={(e) => setValue(e.target.value)}
                    />
                    <div className="thoughtsButtonContainer">
                        {
                            value.length > maxCharsThought ?
                                <Typography color="error" data-testid="thought-pool-max-chars">
                                    <Trans i18nKey="thoughtsPool.maxChars"></Trans>
                                </Typography>
                             : <Box/>
                        }
                        <Button
                            data-testid="thought-pool-submit"
                            type="submit"
                            variant="contained"
                            disabled={value.trim() === '' || value.length > maxCharsThought}>
                            <Trans i18nKey="thoughtsPool.post"></Trans>
                        </Button>
                    </div>
                </form>

                {thoughts.map(thought => {
                    return (
                        <Grid sx={{padding: '16px 8px', wordWrap: 'break-word'}} className="borderBottom"
                              key={thought.id} data-testid={'thought-' + thought.id}>
                            {thought.idea}
                        </Grid>
                    );
                })
                }
            </CardContent>

        </Card>
    );
}