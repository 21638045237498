import React, {useState} from 'react';
import {Card, CardContent, Tabs, Tab } from '@mui/material';
import {
    BarPlot,
    ChartsTooltip,
    ChartsXAxis,
    ChartsYAxis,
    LinePlot,
    MarkPlot, ResponsiveChartContainer
} from '@mui/x-charts';
import CardHeading from '../shared/CardHeading';
import {TabPanel} from '../shared/TabPanel';
import {t} from 'i18next';


export function EvaluationCard() {

    const [tab, setTab] = useState(0);

    const handleChangeTab = (event: React.SyntheticEvent, newTabValue: number) => {
        setTab(newTabValue);
    };

    return (
        <Card sx={{minWidth: 275, mt: 3}}>
            <CardContent>
                <CardHeading dataTestId="evaluation-card-title" i18nKeyTitle="evaluationCard.title"/>
                <Tabs value={tab} onChange={handleChangeTab}>
                    <Tab label={t('evaluationCard.mood')} data-testid="mood-tab"/>
                    <Tab label={t('evaluationCard.workPlace')} data-testid="workplace-tab"/>
                </Tabs>

                <TabPanel value={tab} index={0}>
                    <ResponsiveChartContainer data-testid="mood-chart"
                                              series={[{type: 'line', data: [2, 3, 4, 1, 5], area: true}]}
                                              xAxis={[
                                                  {
                                                      data: ['01.11', '02.11', '03.11', '04.11', '05.11'],
                                                      scaleType: 'band',
                                                  },
                                              ]}
                                              height={300}>
                        <LinePlot/>
                        <ChartsXAxis label={t('evaluationCard.date')} position="bottom"/>
                        <ChartsYAxis label={t('evaluationCard.avgMood')}/>
                        <MarkPlot/>
                        <ChartsTooltip/>
                    </ResponsiveChartContainer>
                </TabPanel>

                <TabPanel value={tab} index={1}>
                    <ResponsiveChartContainer data-testid="workplace-chart"
                                              series={[
                                                  {
                                                      type: 'bar',
                                                      data: [3, 4, 1, 2, 3],
                                                      stack: 'total',
                                                      label: 'Home Office'
                                                  },
                                                  {
                                                      type: 'bar',
                                                      data: [4, 3, 1, 2, 3],
                                                      stack: 'total',
                                                      label: 'Customer'
                                                  },
                                                  {
                                                      type: 'bar',
                                                      data: [4, 2, 5, 2, 3],
                                                      stack: 'total',
                                                      label: 'On Site'
                                                  },
                                              ]}
                                              xAxis={[{
                                                  scaleType: 'band',
                                                  data: ['01.11', '02.11', '03.11', '04.11', '05.11']
                                              }]}
                                              height={300}>
                        <ChartsTooltip/>
                        <BarPlot/>
                        <ChartsXAxis label={t('evaluationCard.date')} position="bottom"/>
                        <ChartsYAxis label={t('evaluationCard.amount')}/>
                    </ResponsiveChartContainer>
                </TabPanel>
            </CardContent>
        </Card>
    );
}