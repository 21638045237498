import {createTheme, ThemeOptions} from '@mui/material/styles';

const defaultTheme = createTheme({
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    backgroundColor: '#FFE9D033',
                    paddingTop: '50px',
                    fontSize: '0.875rem',
                    lineHeight: 1.43,
                    letterSpacing: '0.01071em',
                    fontFamily: 'inter',
                },
            },
        },
        MuiLink: {
            defaultProps: {
                underline: 'none',
            },
            styleOverrides: {
                root: ({theme}) => ({
                    '&.active': {
                        color: '#515081',
                        textDecoration: 'underline',
                        fontWeight: 'bold',
                    },
                }),
            }
        },
        MuiTextField: {
            defaultProps: {
                variant: 'outlined',
                margin: 'normal',
            },
            styleOverrides: {
                root: ({theme}) => ({
                    backgroundColor: '#FBFCFE',
                    borderRadius: 6,
                    border: '1px solid #CDD7E1',
                    '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                            border: '2px solid #F8A941',
                        },
                        '&.Mui-focused fieldset': {
                            border: '2px solid #F8A941',
                        },
                    },
                }),
            }
        },
        MuiStepIcon: {
            styleOverrides: {
                root: {
                    '&.Mui-active': {
                        color: '#515081',
                    },
                    '&.Mui-completed': {
                        color: '#515081',
                    },
                    '&.Mui-disabled': {
                        color: '#515081',
                    },
                },
            },
        },
        // MuiInputLabel: {
        //     styleOverrides: {
        //         root: {
        //             overflow: 'hidden',
        //             textOverflow: 'ellipsis',
        //             whiteSpace: 'nowrap',
        //             width: '70%',
        //         },
        //         shrink: {
        //             width: '100%',
        //         },
        //     },
        // },
        MuiButton: {
            defaultProps: {
                disableRipple: true,
                disableFocusRipple: true,
            },
            styleOverrides: {
                root: ({ownerState, theme}) => ({
                    textTransform: 'none',
                    boxShadow: 'none',
                    borderRadius: 8,
                    backgroundColor: '#C5C3FF33',
                    color: '#515081',
                    '&:hover': {
                        boxShadow: 'none',
                        backgroundColor: '#C5C3FF66',
                    },
                    '&.active': {
                        backgroundColor: theme.palette.primary.dark,
                        color: theme.palette.getContrastText(
                            ownerState.style?.backgroundColor ||
                            theme.palette.primary.dark
                        ),
                    },
                }),
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        backgroundColor: 'rgba(0,0,0,0.4)',
                    },
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    padding: '8px 16px',
                    border: '1px solid #FBFCFE',
                    borderRadius: '16px',
                    boxShadow: '0px 2px 8px -2px rgba(234, 145, 44, 0.12), 0px 12px 16px -4px rgba(234, 145, 44, 0.12);'
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    spacing: '16px',
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    fontSize: 13,
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: ({theme}) => ({
                    color: 'black',
                    '&.Mui-focused': {
                        color: 'black',
                    },
                }),
            },
        }
    },
});

export type ExtendedThemeOptions = ThemeOptions & {
    spacingValue: (spacing: number) => number;
};

const themeOptions: ExtendedThemeOptions = {
    spacingValue: (spacing: number) =>
        parseInt(defaultTheme.spacing(spacing), 10),
    typography: {
        fontFamily: 'inter',
    },
    palette: {},
};

export const theme = createTheme({...defaultTheme, ...themeOptions});
