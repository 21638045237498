import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField, Typography
} from '@mui/material';
import {Trans} from 'react-i18next';
import {t} from 'i18next';
import {TeamModalDialogProps} from '../../model/Models';
import {TeamService} from '../../services/TeamService';
import React from 'react';

export function TeamModalDialog(props: Readonly<TeamModalDialogProps>) {

    function saveTeam() {
        TeamService.createOrUpdateTeam({id: props.team.id, name: props.team.name}).then(() => {
            props.onClose();
        });
    }

    function onChangeTeamName(teamName: string) {
        props.onChange({id: props.team.id, name: teamName});
    }

    return (
        <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth={'md'}>
            <DialogTitle>
                <Typography data-testid="team-modal-title">
                    <Trans>{props.team.id === '' ? t('administration.createTeam') : t('administration.updateTeam')}</Trans>
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Typography data-testid="team-modal-content">
                    <Trans>{props.team.id === '' ? t('administration.enterTeamName') : t('administration.changeTeamName')}</Trans>
                </Typography>
                <TextField
                    required
                    id="teamName"
                    name="teamName"
                    label={t('administration.team.name')}
                    type="text"
                    fullWidth
                    onChange={(e) => onChangeTeamName(e.target.value)}
                    value={props.team.name}
                    slotProps={{htmlInput: {'data-testid': 'teamName-input'}}}
                    variant="outlined"
                />
                {props.team.name.trim() === '' &&
                    <Typography color="error" data-testid="team-name-required">
                        <Trans i18nKey="administration.team.nameRequired"></Trans>
                    </Typography>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} data-testid="team-modal-cancel">
                    <Trans i18nKey="general.cancel"></Trans>
                </Button>
                <Button onClick={saveTeam} disabled={props.team.name.trim() === ''} data-testid="team-modal-save">
                    <Trans i18nKey="general.save"></Trans>
                </Button>
            </DialogActions>
        </Dialog>
    );
}