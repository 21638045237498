import {Card, CardContent} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {CheckInService} from '../../services/CheckInService';
import CardHeading from '../shared/CardHeading';

export default function MoodCard() {

    const buttonCaptions: Record<number, string> = {
        1: 'angry_face.svg',
        2: 'slightly_frowning_face.svg',
        3: 'neutral_face.svg',
        4: 'slightly_smiling_face.svg',
        5: 'face_with_heart_shaped_eyes.svg'
    };

    const [moods, setMoods] = useState<Record<number, number>>();

    function getAllMoods(): void {
        CheckInService.getAllMoods().then(result => {
            setMoods(result);

        });
    }

    useEffect(() => {
        getAllMoods();
    }, []);

    function updateMood(mood: number) {
        CheckInService.updateMood(mood, null).then(() => getAllMoods());
    }

    function hasMood(key: string) {
        return moods && moods[Number(key)];
    }

    const totalCounts = moods ? Object.values(moods).reduce((acc: number, count: number) => acc + count, 0) : 0;

    return (
        <Card sx={{minWidth: 275}}>
            <CardContent>
                <CardHeading i18nKeyTitle="mood.moodQuestion" dataTestId="mood-card-title" />
                <div className="moodTracker">
                    {
                        Object.entries(buttonCaptions).map(([key, value]) => {
                            const barHeight = moods ? (moods[Number(key)] / totalCounts) * 100 : 0;
                            return (
                                <div key={key}>
                                    {moods ?
                                        <div className="moodBarArea">
                                            { hasMood(key) &&
                                                <div>
                                                    <div className="moodBar" data-testid={'mood-bar-' + key}
                                                         style={{height: barHeight + 'px'}}>
                                                    </div>
                                                </div>
                                            }
                                        </div> : null
                                    }
                                    <img onClick={() => updateMood(Number(key))} className="faceIcon"
                                         data-testid={'face-icon-' + key}
                                         src={`/icons/${value}`} alt={value}></img>
                                </div>

                            );
                        })
                    }
                </div>
            </CardContent>
        </Card>
    );
}