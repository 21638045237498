import api from '../config/AxiosConfig';
import {UserService} from './UserService';
import {Team} from '../model/Models';

export class TeamService {

    static async getTeamsOfCompany(): Promise<Record<string, string>> {
        const response = await api.get('/team/company/' + UserService.getCurrentUser()?.companyId);
        return response.data;
    }

    static async deleteTeam(teamId: string): Promise<Team[]> {
        const response = await api.delete('/team/' + teamId);
        return response.data;
    }

    static async createOrUpdateTeam(team: Team): Promise<void> {
        const response = await api.post('/team', team);
        return response.data;
    }

}
