import api from '../config/AxiosConfig';
import {UserService} from './UserService';
import {Thought} from '../model/Models';

export class ThoughtsPoolService {

    static async getAllThoughts(): Promise<Thought[]> {
        const response = await api.get('/think-tank/' + UserService.getCurrentUser()?.companyId);
        return response.data;
    }

    static async saveThoughts(idea: string): Promise<void> {
        let response = await api.post('/think-tank', {idea: idea});
        return response.data;
    }
}
