import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    ListItemText,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography
} from '@mui/material';
import {GenkiRole, User, UserModalDialogProps} from '../../model/Models';
import {Trans} from 'react-i18next';
import {t} from 'i18next';
import React from 'react';
import {UserService} from '../../services/UserService';

export function UserModalDialog(props: Readonly<UserModalDialogProps>) {

    function saveUser() {
        const user: User = {
            companyId: '',
            email: props.user.email,
            firstName: props.user.firstName,
            id: props.user.id,
            lastName: props.user.lastName,
            locked: props.user.locked,
            role: GenkiRole.USER,
            teams: props.user.teams,
        };
        UserService.createOrUpdateUser(user).then(() => {
            props.onClose();
        });
    }

    function onChangeFirstName(firstName: string) {
        props.onChange({
            companyId: '',
            email: props.user.email,
            firstName: firstName,
            id: props.user.id,
            lastName: props.user.lastName,
            locked: props.user.locked,
            role: GenkiRole.USER,
            teams: props.user.teams,
        });
    }

    function changeTeams(event: SelectChangeEvent<string[]>) {
        props.onChange({
            companyId: '',
            email: props.user.email,
            firstName: props.user.firstName,
            id: props.user.id,
            lastName: props.user.lastName,
            locked: props.user.locked,
            role: GenkiRole.USER,
            teams: event.target.value as string[],
        });
    }

    function onChangeLastName(lastName: string) {
        props.onChange({
            companyId: '',
            email: props.user.email,
            firstName: props.user.firstName,
            id: props.user.id,
            lastName: lastName,
            locked: props.user.locked,
            role: GenkiRole.USER,
            teams: props.user.teams,
        });
    }

    function onChangeEmail(email: string) {
        props.onChange({
            companyId: '',
            email: email,
            firstName: props.user.firstName,
            id: props.user.id,
            lastName: props.user.lastName,
            locked: props.user.locked,
            role: GenkiRole.USER,
            teams: props.user.teams,
        });
    }

    function isDisabled(): boolean {
        if (props.user.email.trim() === '') {
            return true;
        }
        if (props.user.firstName.trim() === '') {
            return true;
        }
        if (props.user.lastName.trim() === '') {
            return true;
        }
        return props.user.teams.length === 0;
    }

    return (
        <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth={'md'}>
            <DialogTitle>
                <Typography data-testid="user-modal-title">
                    <Trans i18nKey={props.user.id === '' ? 'administration.createUser' : 'administration.updateUser'}/>
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Typography data-testid="user-modal-content">
                    <Trans i18nKey={props.user.id === '' ? 'administration.enterUserInfo' : 'administration.changeUserInfo'}/>
                </Typography>
                <TextField
                    required
                    id="firstName"
                    name="firstName"
                    label={t('administration.user.firstName')}
                    type="text"
                    fullWidth
                    variant="outlined"
                    slotProps={{htmlInput: {'data-testid': 'firstName'}}}
                    onChange={(e) => onChangeFirstName(e.target.value)}
                    value={props.user.firstName}
                />

                {props.user.firstName.trim() === '' &&
                    <Typography color="error" data-testid="user-firstName-required">
                        <Trans i18nKey="administration.user.firstNameRequired"></Trans>
                    </Typography>
                }
                <TextField
                    required
                    id="lastName"
                    name="lastName"
                    label={t('administration.user.lastName')}
                    type="text"
                    fullWidth
                    variant="outlined"
                    slotProps={{htmlInput: {'data-testid': 'lastName'}}}
                    onChange={(e) => onChangeLastName(e.target.value)}
                    value={props.user.lastName}
                />
                {props.user.lastName.trim() === '' &&
                    <Typography color="error" data-testid="user-lastName-required">
                        <Trans i18nKey="administration.user.lastNameRequired"></Trans>
                    </Typography>
                }
                <TextField
                    required
                    id="email"
                    name="email"
                    label={t('general.email')}
                    type="email"
                    fullWidth
                    variant="outlined"
                    slotProps={{htmlInput: {'data-testid': 'email'}}}
                    onChange={(e) => onChangeEmail(e.target.value)}
                    value={props.user.email}
                />
                {props.user.email.trim() === '' &&
                    <Typography color="error" data-testid="user-email-required">
                        <Trans i18nKey="administration.user.emailRequired"></Trans>
                    </Typography>
                }

                <Select
                    label={t('administration.user.teams')}
                    multiple
                    value={props.user.teams}
                    onChange={changeTeams}
                    renderValue={(selected) => {
                        let result: string[] = [];
                        for (const selectedElement of selected) {
                            result.push(props.availableTeams[selectedElement]);
                        }
                        return result.join(', ');
                    }}
                    fullWidth
                    data-testid="team-select"
                    variant="outlined">
                    {Object.entries(props.availableTeams).map(([teamId, teamName]) => {
                        return (
                            <MenuItem key={teamId} value={teamId}>
                                <Checkbox checked={props.user.teams.includes(teamId)} data-testid={teamId}/>
                                <ListItemText primary={teamName}/>
                            </MenuItem>
                        );
                    })
                    }
                </Select>

                {props.user.teams.length === 0 &&
                    <Typography color="error" data-testid="user-teams-required">
                        <Trans i18nKey="administration.user.teamsRequired"></Trans>
                    </Typography>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} data-testid="cancel-button">
                    <Trans i18nKey="general.cancel"></Trans>
                </Button>
                <Button onClick={saveUser} disabled={isDisabled()} data-testid="save-button">
                    <Trans i18nKey="general.save"></Trans>
                </Button>
            </DialogActions>;
        </Dialog>
    );
}