import React, {useEffect, useState} from 'react';
import SnackBar from '@mui/material/Snackbar';
import {setupAxiosInterceptor} from '../../config/AxiosConfig';
import {Alert} from '@mui/material';
import {Trans} from 'react-i18next';

export default function ErrorSnackBar() {

    const [error, setError] = useState<string>('');
    const [open, setOpen] = useState<boolean>(false);


    useEffect(() => {
        setupAxiosInterceptor({setError: setError});
    }, []);

    useEffect(() => {
        if (error) {
            setOpen(true);
        }
    }, [error]);

    const handleClose = () => {
        setOpen(false);
        setError('');
    };

    return (
        <SnackBar
            anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
        >
            <Alert
                onClose={handleClose}
                severity="error"
                variant="filled"
                sx={{width: '100%'}}
            >
                <Trans i18nKey={'errors.' + error}></Trans>
            </Alert>
        </SnackBar>
    );
}