import {Navigate, Outlet} from 'react-router-dom';
import * as React from 'react';
import {UserService} from '../../services/UserService';

export function PrivateRoute() {
    const token = localStorage.getItem('token');
    return token == null ? <Navigate to="/login"/> : <Outlet/>;
}

export function AdminRoute() {
    const token = localStorage.getItem('token');
    const currentUser = UserService.getCurrentUser();
    return token != null && currentUser?.role === 'ADMIN' ? <Outlet/> : <Navigate to="/login"/>;
}